<template>
  <v-layout wrap class="justify-center">
    <v-flex lg12 md12 sm12 pr-2>
      <v-container container--fluid>
        <v-layout>
          <v-breadcrumbs
            style="padding-left: 0px; padding-top: 5px; padding-bottom: 5px;"
            :items="breadcrumbs"
            divider="/"
          ></v-breadcrumbs>

        </v-layout>
        <v-layout>
          <page-title>User Details</page-title>

          <div class="ml-4 mt-1 mb-2">
            <v-btn class="secondary--text mt-2 mr-3" text  small @click="refresh()">
              <v-icon small class="mr-1">refresh</v-icon>
              Refresh
            </v-btn>

            <AdjustBalance :projectId="project.id" :user="userId" />
          </div>
        </v-layout>

        <!-- Error message -->
        <v-layout>
          <div v-if="error">
            <v-alert type="error" dense outlined>
              {{ error }}
            </v-alert>
          </div>
        </v-layout>
        
        <v-layout class="mt-4 mb-4">          
          <v-flex lg9 md9 sm12>
            <v-card >
              <v-card-title>
                <span class="secondary--text text-subtitle-1">
                  Daily Charges
                </span>
              </v-card-title>
              <div class="pt-2 mb-2" id="frost-chart" ></div>
            </v-card>
          </v-flex>

          <v-flex lg3 md3 sm12>
            <v-card flat class="ml-2" height="98%">
              <v-card-title class="secondary--text text-subtitle-1">
                <v-icon class="mr-2" small>mdi-cash</v-icon>

                <v-tooltip right>
                  <template v-slot:activator="{ on: tooltip }">
                    <span v-on="{ ...tooltip }"> 
                      Credits Utilization
                    </span>
                  </template>
                  Last 30 days
                </v-tooltip>
              </v-card-title>

              <v-card-text>
                <v-simple-table dark>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="secondary--text">Balance</td>
                        <td>  
                          <span class="font-weight-bold">
                            {{ balance }}
                          </span>                    
                        </td>
                      </tr>

                      <tr>
                        <td class="secondary--text">Used (7 days)</td>
                        <td>
                          <span v-if="dailyCostsTotal" class="font-weight-bold">
                            {{Math.abs(dailyCostsTotal.toFixed(2))}}
                          </span>
                            
                        </td>
                      </tr>
                      <tr>
                        <td class="secondary--text">Top Ups</td>
                        <td>  
                          <span class="font-weight-bold">
                            {{ topUpsTotal }}
                          </span>                    
                        </td>
                      </tr>
                      <tr>
                        <td class="secondary--text"></td>
                        <td></td>
                      </tr>

                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>

            </v-card>
          </v-flex>

        </v-layout>                
        <v-layout class="mt-4">        
          <div class="tbcard">
            <v-card>
              <v-card-title>
                <span class="secondary--text text-subtitle-1">
                  Activity
                </span>
              </v-card-title>
              <v-data-table :key="tableKey" :headers="headers" :items="items"
                hide-default-footer :search="e3" :loading="loading" :server-items-length="pagination.totalItems"
                loading-text="Loading... Please wait">

                <template v-slot:top>
                  <v-row>
                    <v-layout wrap class="justify-end mt-5 mr-5 ml-5">

                    </v-layout>
                  </v-row>
                </template>

                <template v-slot:[`item.createdAt`]="{ item }">
                  <v-tooltip top :key="`del-${item.id}`">
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                        {{ item.createdAt | ago }}
                      </span>
                    </template>
                    <span>{{ item.createdAt | date }}</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.modelId`]="{ item }">
                  <router-link  class="no-underline" :to="{
                      name: 'modelDetails',                                
                      params: {
                        modelId: item.modelId,
                      },
                    }">
                    {{ getModelName(item.modelId) }}
                  </router-link>
                </template>       

                <template v-slot:[`item.amount`]="{ item }">
                  <v-tooltip top :key="`del-${item.id}`">
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                        {{ item.amount.toFixed(4) }}
                      </span>
                    </template>
                    <span>{{ item.amount }}</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.actions`]="{  }">
                  <v-menu class="items" top left transition="v-slide-y-transition">
                    <template v-slot:activator="{ on: on }">
                      <v-btn icon v-on="on" :class="
                        $store.state.theme === 'light' ? 'secondary--text' : ''
                      ">
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <v-list-item>
                        <v-list-item-title disabled>user details</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title disabled>revert transaction</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
              <div class="pr-2 pb-2">
                <v-layout class="mt-2">
                  <v-spacer></v-spacer>
                  <span class="text-caption mt-3 mr-4">Rows per page:</span>
                  <div class="rows-select">
                    <v-flex shrink class="select">
                      <v-select class="mb-2" width="25" v-model="itemsPerPage" :items="itemsPerPageOptions" dense
                        label="Rows per page" single-line></v-select>
                    </v-flex>
                  </div>
                  <span class="text-caption mt-3 mr-4 ml-4">Total items: {{ pagination.totalItems }}</span>
                  <v-btn class="mr-2 mt-2" icon small router :disabled="pagination.previousPageToken == '' ? true : false"
                    :to="{
                      name: 'userDetails',
                      params: {
                        userId: userId,
                      },
                      query: {
                        pageToken: pagination.previousPageToken,
                      },
                    }">
                    <v-icon>navigate_before</v-icon>
                  </v-btn>
                  <!-- Navigate to the next page based on the token -->
                  <v-btn class="ml-2 mt-2" icon small router :disabled="pagination.nextPageToken == '' ? true : false"
                    :to="{
                      name: 'userDetails',
                      params: {
                        userId: userId,
                      },
                      query: {
                        pageToken: pagination.nextPageToken,
                      },
                    }">
                    <v-icon>navigate_next</v-icon>
                  </v-btn>
                </v-layout>
              </div>
            </v-card>
          </div>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<style lang="stylus">
.tbcard {
  width: 100%;
  display: block;
}
</style>

<script>
import { Chart } from "frappe-charts"

import PageTitle from '../PageTitle';
import AdjustBalance from "./AdjustBalance.vue";

export default {
  components: {
    PageTitle,
    AdjustBalance    
},
  data() {
    return {
      tableKey: '',
      e3: null,
      user: '',
      itemsPerPage: 10,
      itemsPerPageOptions: [
        10,
        20,
        200,
        500,
      ],
    }
  },

  watch: {
    // Table pagination
    itemsPerPage: {
      handler() {
        // If user is changing items per page, we kinda need
        // to reset the token, otherwise going backwards might
        // be weird
        let reset = this.itemsPerPage !== this.pagination.pageSize ? true : false
        this.$store.dispatch('SetEndUserTransactionsPageSize', this.itemsPerPage).then(() => {
          
        if (reset && this.pageTokenFromUrl) {
          this.$router.push({ 
            name: 'transactions',
            params: {
              modelId: this.modelId,
            },
          }).catch(() => { })
        } else {
          this.loadItems()
        }
        })
      }
    },
    status: {
      handler() {
        this.loadItems()
      }
    },
    $route() {
      this.loadItems()
    }
  },

  computed: {
    userId() {
      return this.$route.params.userId
    },
    project() {
      return this.$store.state.user.user?.projects[0]
    },
    pageTokenFromUrl() {
      return this.$route.query.pageToken
    },
    models() {
      return this.$store.state.model.models
    },
    loading() {
      return this.$store.state.endUserTransactions.loading
    },
    error() {
      return this.$store.state.endUserTransactions.error
    },
    items() {
      return this.$store.state.endUserTransactions.transactions
    },
    topUps() {
      return this.$store.state.endUserTransactions.topUps
    }, 
    topUpsTotal() {
      let total = 0
      for (let i = 0; i < this.topUps.length; i++) {
        total += this.topUps[i].amount
      }
      return total
    }, 
    balance() {
      return this.$store.state.endUserTransactions.balance
    },
    dailyCosts() {
      return this.$store.state.endUserTransactions.dailyCosts
    },
    dailyCostsTotal() {
      return this.$store.state.endUserTransactions.dailyCostsTotal
    },
    headers() {
      return [
        { text: 'Transaction Time', align: 'left', value: 'createdAt', sortable: false },
        { text: 'Model', value: 'modelId', align: 'left' },
        { text: 'Amount', value: 'amount', align: 'left' },
        { text: '', value: 'actions', sortable: false, align: 'right' } 
      ]
    },
    pagination() {
      return this.$store.state.endUserTransactions.pagination
    },
    breadcrumbs() {      
      return [
        {
          text: 'Home',
          disabled: false,
          to: '/',
        },
        {
          text: 'Users',
          disabled: true,
          href: '',     
        },
        {
          text: `${this.userId}`,
          disabled: true,
          href: '',
        },
      ]
    }
  },

  async mounted() {
    this.itemsPerPage = this.pagination.pageSize

    this.refresh()    
  },

  methods: {
    async refresh() {
      // Loading user for the project
      await this.$store.dispatch('GetUser')

      // Getting models to populate the names
      this.$store.dispatch('ListModels', { projectId: this.project.id })

      // Load the transactions
      await this.loadItems()

      // Getting the model
      const q = {
        projectId: this.project.id,
        user: this.userId
      }
      
      await this.$store.dispatch('ListEndUsersUsage', q)
      await this.$store.dispatch('ListEndUserTopUps', q) // Just topups

      this.$store.dispatch('GetBalance', q)

      this.loadChart()

      this.$nextTick(() => {
        this.tableKey = Date.now()
      })
    },    
    async loadItems() {
      if (!this.project) {
        return
      }

      let q = {
        projectId: this.project.id,
        pageSize: this.pagination.pageSize,
        pageToken: this.pageTokenFromUrl ? this.pageTokenFromUrl : '',
        user: this.userId,        
      }

      await this.$store.dispatch('ListEndUserTransactions', q)
    },
    getModelName(modelId) {
      let model = this.models.find(m => m.id === modelId)
      if (model) {
        return model.name
      }
      return modelId
    },
    loadChart() {
      // Get labels from usage
      let date = []
      let costs = []

      for (let i = 0; i < this.dailyCosts.length; i++) {
        // Convert date string "2023-12-28T00:00:00Z" into day and month, i.e. "28th Dec"
        let d = new Date(this.dailyCosts[i].date)
        let day = d.getDate()
        let month = d.toLocaleString('default', { month: 'short' })
        date.push(`${day} ${month}`)

        // Gets abs value of cost
        costs.push(Math.abs(this.dailyCosts[i].totalAmount.toFixed(4)))
      }

      new Chart( "#frost-chart", {                    
        data: {
          labels: date,

        datasets: [
            {
              name: "Total spend", chartType: 'bar',
              values: costs
            },
          ],
        },
        
        type: 'bar', // or 'bar', 'line', 'pie', 'percentage'
        height: 250,
        colors: ['#0ea5e9'],

        axisOptions: {
          xAxisMode: "tick",
          xIsSeries: true
        },

        tooltipOptions: {
          formatTooltipX: d => (d + ''),
          formatTooltipY: d => d + ' credits',
        }
      });      
    }
  }
}
</script>
